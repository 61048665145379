import { store } from "..";
import { ADD_NOTIFICATION } from "../store/types";
import { deletePatient } from "./api/adt.api";

const deletePatientData = async (id) => {
    try {
        const res = await deletePatient(id);
        if (res && res?._id) {
            store.dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "success",
                    label: "ADT deleted successfully",
                    id: "deleteADTError",
                },
            });
        }
        return res;
    } catch (e) {
        store.dispatch({
            type: ADD_NOTIFICATION,
            payload: {
                type: "error",
                label: e?.response?.data?.message || "Error while trying to delete ADT.",
                id: "deleteADTError",
            },
        });
    }
};


export {
    deletePatientData,
}
