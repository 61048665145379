const PickProjectionDaysSVG = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32" fill="none">
			<path
				d="M29.2278 17.6866C29.2975 17.1342 29.3334 16.5713 29.3334 16C29.3334 8.63616 23.3638 2.66663 16.0001 2.66663C8.63628 2.66663 2.66675 8.63616 2.66675 16C2.66675 23.3637 8.63628 29.3333 16.0001 29.3333C16.5806 29.3333 17.1525 29.2962 17.7134 29.2242M16.0001 7.99996V16L20.9846 18.4922M25.3334 29.3333V21.3333M21.3334 25.3333H29.3334"
				stroke="currentColor"
				stroke-width="2.66667"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default PickProjectionDaysSVG;
