/* eslint-disable eqeqeq */
import { ResponsiveLine } from "@nivo/line";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { CHART_POINT_TYPE } from "../../../../types/chart-dialog.type";
import { FILTER_TYPES } from "../../../../types/common.type";
import { itemPercentageChart } from "../../../../utilis/common";
import styles from "../ChartDialogCommon.module.scss";

export default function LineChartDialog({
	chartData,
	chartPointType,
	selectedFacility,
	selectedFilter,
	isSeparateLine,
	censusAverage,
	isOverallTotal,
}) {
	const [data, setData] = React.useState([]);
	const [singleLineChart, setSingleLineChart] = React.useState([]);
	const { defaultFacilities } = useSelector((state) => state.activeFacilities);

	React.useEffect(() => {
		async function fetchMyAPI() {
			let chartDataUpdated = [];
			let chartDataByFacility = [];
			chartData.forEach(async (ele) => {
				const facilityUpdated = ele.facilityIds;
				const facilityWiseDataUpdated = ele.facilityWiseData;

				if (facilityWiseDataUpdated && !_.isEmpty(facilityWiseDataUpdated)) {
					for (const [key, value] of Object.entries(facilityWiseDataUpdated)) {
						let indexFind = chartDataByFacility.findIndex((facilityBy) => facilityBy.keyId === key);

						if (indexFind === -1) {
							let facilityName = value && value.length > 0 ? value[0]?.facility?.name : null;

							if (selectedFacility.includes(key)) {
								let total = value?.length;

								if (isOverallTotal) {
									const allOverAll = _.groupBy(value, "isOutgoing");
									let totalIN = 0;
									let totalOut = 0;
									for (const [key, value] of Object.entries(allOverAll)) {
										const total = value.length;
										if (key !== undefined) {
											if (key == "true") {
												totalOut = total;
											} else {
												totalIN = total;
											}
										}
									}
									total = totalIN - totalOut;
								}

								const percentage = itemPercentageChart(total, censusAverage, "percentage") || 0;

								chartDataByFacility.push({
									id: `${facilityName} (${total})`,
									keyId: key,
									label: facilityName,
									total,
									data: [
										{
											x: ele.x,
											y: total,
											percentage,
											facilityIds: ele.facilityIds,
											label: facilityName,
										},
									],
								});
							}
						} else {
							let existingArray = chartDataByFacility[indexFind];

							let newTotal = value?.length;

							if (isOverallTotal) {
								const allOverAll = _.groupBy(value, "isOutgoing");
								let totalIN = 0;
								let totalOut = 0;
								for (const [key, value] of Object.entries(allOverAll)) {
									const total = value.length;
									if (key !== undefined) {
										if (key == "true") {
											totalOut = total;
										} else {
											totalIN = total;
										}
									}
								}
								newTotal = totalIN - totalOut;
							}

							let updateData = {
								x: ele.x,
								y: newTotal,
								percentage: ele.percentage,
								facilityIds: ele.facilityIds,
							};
							let updatedTotal = existingArray?.total + newTotal;

							const percentage = itemPercentageChart(updatedTotal, censusAverage, "percentage") || 0;

							chartDataByFacility[indexFind] = {
								...existingArray,
								total: updatedTotal,
								percentage: percentage,
								id: `${existingArray.label} (${updatedTotal})`,
								data: [...existingArray?.data, updateData],
							};
						}
					}
				} else {
					// eslint-disable-next-line array-callback-return
					selectedFacility.map((facility) => {
						let indexFind = chartDataByFacility.findIndex((facilityBy) => facilityBy.keyId === facility);
						let selectedFacility = _.find(defaultFacilities, { _id: facility });
						let facilityName = selectedFacility?.name;
						if (indexFind === -1) {
							chartDataByFacility.push({
								id: `${facilityName}`,
								keyId: facility,
								label: facilityName,
								total: 0,
								data: [
									{
										x: ele.x,
										y: 0,
										percentage: 0,
										facilityIds: ele?.facilityIds || [],
										label: facilityName,
									},
								],
							});
						} else {
							let existingArray = chartDataByFacility[indexFind];
							let newTotal = 0;
							let updatedTotal = existingArray?.total + newTotal;
							let updateData = {
								x: ele?.x,
								y: newTotal,
								percentage: ele?.percentage || 0,
								facilityIds: ele?.facilityIds || [],
							};
							chartDataByFacility[indexFind] = {
								...existingArray,
								total: updatedTotal,
								percentage: 0,
								id: `${existingArray.label} (${updatedTotal})`,
								data: [...existingArray?.data, updateData],
							};
						}
					});
				}

				let count = 0;
				if (isOverallTotal) {
					facilityUpdated.forEach((i) => {
						if (selectedFacility.includes(i)) {
							const patientLists = facilityWiseDataUpdated[i];
							const allOverAll = _.groupBy(patientLists, "isOutgoing");
							let totalIN = 0;
							let totalOut = 0;
							for (const [key, value] of Object.entries(allOverAll)) {
								const total = value.length;
								if (key !== undefined) {
									if (key == "true") {
										totalOut = total;
									} else {
										totalIN = total;
									}
								}
							}
							count = totalIN - totalOut;
						}
					});
				} else {
					facilityUpdated.forEach((i) => (selectedFacility.includes(i) ? count++ : count));
				}
				chartDataUpdated.push({
					x: ele.z,
					y: count,
					percentage: ele.percentage,
					facilityIds: ele.facilityIds,
				});
			});

			setSingleLineChart(chartDataUpdated);

			setData(chartDataByFacility);
		}

		fetchMyAPI();
	}, [chartData, selectedFacility, censusAverage, isOverallTotal, defaultFacilities]);

	const getRequiredDateFormat = (timeStamp, format = "MM-DD-YYYY") => {
		return moment.utc(timeStamp).format(format);
	};

	return (
		<div className={styles.chartContainer} id="svg-container">
			{isSeparateLine ? (
				<ResponsiveLine
					data={data}
					xScale={{
						type: "time",
						format: "%m-%d-%Y",
					}}
					xFormat="time:%m-%d-%Y"
					axisBottom={{
						format: "%m-%d-%Y",
						orient: "top",
						...(selectedFilter === FILTER_TYPES.SEVEN_DAYS && {
							tickValues: "every 7 days",
						}),
						...(selectedFilter === FILTER_TYPES.MONTHLY && {
							format: (values) => `${getRequiredDateFormat(values, "MMM")}`,
							tickValues: "every month",
						}),
						...(selectedFilter === FILTER_TYPES.WEEKLY && {
							format: (values) =>
								`(${moment(values).startOf("week").format("MM-DD")})-(${moment(values).endOf("week").format("MM-DD")})`,
							tickValues: "every week",
						}),
						...(selectedFilter === FILTER_TYPES.YEARLY && {
							format: (values) => `${getRequiredDateFormat(values, "YYYY")}`,
							tickValues: "every years",
						}),
						tickPadding: 5,
						tickRotation: -20,
					}}
					yScale={{
						type: "linear",
						min: "auto",
						max: "auto",
						// stacked: true,
						reverse: false,
						tickPadding: 5,
					}}
					legends={[
						{
							anchor: "bottom-left",
							direction: "row",
							translateX: 0,
							translateY: 70,
							itemsSpacing: 0,
							itemDirection: "left-to-right",
							itemWidth: 100,
							itemHeight: 20,
							itemOpacity: 0.75,
							symbolSize: 12,
							symbolShape: "circle",
						},
					]}
					margin={{ top: 30, right: 20, bottom: 140, left: 70 }}
					pointLabel={(data) => {
						if (chartPointType === CHART_POINT_TYPE.PERCENTAGE) {
							return data.percentage;
						} else {
							return data.y;
						}
					}}
					enableGridX={false}
					animate={false}
					colors={{ scheme: "category10" }}
					axisTop={null}
					axisRight={null}
					pointColor={{ theme: "background", inherit: "color" }}
					pointBorderWidth={2}
					pointBorderColor={{ from: "serieColor" }}
					useMesh={true}
					enablePointLabel={true}
				/>
			) : (
				<>
					<ResponsiveLine
						data={[
							{
								id: "graph",
								color: "hsl(19, 70%, 50%)",
								data: singleLineChart,
							},
						]}
						margin={{ top: 30, right: 20, bottom: 120, left: 70 }}
						xScale={{ type: "point" }}
						axisBottom={{
							orient: "top",
							tickSize: 1,
							tickPadding: 5,
							tickRotation: -20,
						}}
						yScale={{
							type: "linear",
							min: "auto",
							max: "auto",
							// stacked: true,
							reverse: false,
							tickPadding: 5,
						}}
						pointLabel={(data) => {
							if (chartPointType === CHART_POINT_TYPE.PERCENTAGE) {
								return data.percentage;
							} else {
								return data.y;
							}
						}}
						enableGridX={false}
						animate={false}
						axisTop={null}
						axisRight={null}
						pointColor={{ theme: "background", inherit: "color" }}
						pointBorderWidth={2}
						pointBorderColor={{ from: "serieColor" }}
						useMesh={true}
						enablePointLabel={true}
					/>
				</>
			)}
		</div>
	);
}
