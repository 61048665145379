/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Checkbox, Chip, Divider,  Grid, IconButton, InputAdornment, ListItemText, MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material";
import classNames from "classnames";
import _, { includes } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AddNewSvg from "../../assets/svgs/AddNewSvg.svg";
import { addActiveFacility, setActiveFacilities, setDefaultFacilities } from "../../store/reducers/activeFacilities.slice";
import { setFilterDateUpdate } from "../../store/reducers/common.slice";
import { setcomparingAgainstDifferentFacility, setResetComparison } from "../../store/reducers/comparisonReducers/comparison.slice";
import { setFacilityIds, setMultipleFacility } from "../../store/reducers/permission.slice";
import { SET_FACILITIES } from "../../store/types";
import { getTooltipTitle } from "../../utilis/common";
import CustomPopover from "../shared/custom-popover";
import HtmlTooltip from "../shared/HtmlTooltip";
import NoFacilitySelectionDialog from "../shared/NoFacilitySelectionDialog";
import Scrollbar from "../shared/scrollbar";
import styles from "./Header.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import BusinessIcon from '@mui/icons-material/Business';
import useSetParamToURL from "../hooks/useSetParamToURL";

const FacilitySelection = (props) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState('');
	const [isFacilitySelected, setIsFacilitySelected] = useState(false);
	const facilities = useSelector((state) => state.facility);
	const { isFacilityLoaded } = useSelector((state) => state.common);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
	const activeFacilitiesComparison = useSelector((state) => state.comparison.activeComparisonFacilitiesId);
	const [selectAll, setSelectAll] = useState(false);
	const { setParamToURL } = useSetParamToURL();
	const [selectedFacility, setSelectedFacility] = useState([]);
	const { facilityRef, facilityAnchorEl, setFacilityAnchorEl } = props;

	useEffect(() => {
		if (isFacilityLoaded && activeFacilities.length === 0 && location.pathname !== "/settings/facilities") {
			setIsFacilitySelected(true);
		}
		if (facilities.length === activeFacilities.length) {
			setSelectAll(true);
		}
	}, [activeFacilities, location, isFacilityLoaded]);

	useEffect(() => {
		let changedFlag = false;
		let mappedFacilities = [...activeFacilities];

		if (activeFacilities.length === 0 && facilities[0]) {
			dispatch(setDefaultFacilities(facilities))
			let activeFacilityDefault = _.orderBy(facilities, "name", "ASC")?.[0]?._id || facilities[0]._id
			dispatch(addActiveFacility(activeFacilityDefault));
			localStorage.setItem("facilityId", activeFacilityDefault);
			setParamToURL([{ key: "facilityId", value: activeFacilityDefault }]);
		} else {
			if (activeFacilities && activeFacilities.length > 1) {
				dispatch(setMultipleFacility(true));
				dispatch(setFacilityIds(activeFacilities));
			} else {
				dispatch(setMultipleFacility(false));
			}
		}

		// removing duplicates from activeFacilities
		if (new Set(activeFacilities).size !== activeFacilities.length) {
			changedFlag = true;
			mappedFacilities = [...new Set(activeFacilities)];
		}

		// checking if there is a building in the active facilities that does not belong in facilities
		// (for example was deleted from facilities but it is still left in activeFacilities.)
		if (Array.isArray(facilities) && activeFacilities.some((x) => facilities.every((y) => y._id !== x))) {
			changedFlag = true;
			mappedFacilities = activeFacilities.filter((x) => facilities.some((y) => y._id === x));
		}
		setSelectedFacility(activeFacilities);
		changedFlag && dispatch(setActiveFacilities(mappedFacilities));
	}, [dispatch, facilities, activeFacilities]);

	const getFacilityName = useCallback(() => {
		if (!activeFacilities || !Array.isArray(facilities)) return;
		const sAccount = facilities?.find((acc) => {
			return acc._id === activeFacilities[0];
		});
		return sAccount ? sAccount.name : "Select Facility";
	}, [facilities, activeFacilities]);

	const handleChangeFacility = useCallback(
		(facility) => {
			let facilityId = facility._id;
			if (selectedFacility.indexOf(facilityId) === -1) {
				let latestSelectedFacility = [...selectedFacility, facilityId];
				setSelectedFacility(latestSelectedFacility);
			} else {
				const index = selectedFacility.findIndex((x) => x === facilityId);
				const facilitiesCopy = [...selectedFacility];

				if (index !== -1) {
					facilitiesCopy.splice(index, 1);
					setSelectedFacility(facilitiesCopy);
				}
			}
		},
		[dispatch, selectedFacility, activeFacilitiesComparison]
	);

	const handleToggleAll = useCallback(() => {
		let selectedAllData = !selectAll;
		if (selectedAllData) {
			setSelectedFacility(() => facilities?.map((item) => item._id));
		} else {
			setSelectedFacility([]);
		}

		setSelectAll(selectedAllData);
	}, [setSelectAll, selectAll, facilities, selectedFacility]);

	const handleFacilityRun = useCallback(() => {
		dispatch(setActiveFacilities(selectedFacility));
		dispatch(setFilterDateUpdate(true));

		if (_.isEqual(_.sortBy(activeFacilities), _.sortBy(activeFacilitiesComparison))) {
			dispatch(setcomparingAgainstDifferentFacility(false));
		} else {
			dispatch(setcomparingAgainstDifferentFacility(true));
		}

		dispatch({ type: SET_FACILITIES, payload: "refreshing" });
		localStorage.setItem("facilityId", selectedFacility.length > 0 ? selectedFacility[0] : null);
		setParamToURL([{ key: "facilityId", value: selectedFacility.length > 0 ? selectedFacility[0] : null }]);
		setSelectedFacility([]);
		dispatch(setResetComparison(true));
	}, [selectedFacility]);

	return (
		<>
			<div className={`${styles.selectWrpr} ${!props.expanded ? styles.expandedAccount : ""}`}>
				{props.expanded ? (
					<p
						className={`${styles.selectOpener} ${!facilities?.length ? styles.disabled : ""}`}
						ref={facilityRef}
					>
						{getFacilityName()}
						<Box component={"span"} display={"flex"}>
							{activeFacilities && Array.isArray(facilities) && activeFacilities?.length > 1 ? (
								<HtmlTooltip
									content={getTooltipTitle(facilities, activeFacilities)}
									arrow
									children={
										<Chip color="primary" variant="filled" size="small" label={`+ ${activeFacilities?.length - 1}`} />
									}
								/>
							) : null}
							<Box sx={{ mr: 0.5 }} />
							<span className={classNames(facilityAnchorEl && styles.active, styles.selectMenuIcon)}>
								<AddNewSvg />
							</span>
						</Box>
					</p>
				) : (
					<>
						<Tooltip title="Facilities">
							<IconButton
								ref={facilityRef}
								size="small"
								style={{ backgroundColor: 'transparent' }}
							>
								<BusinessIcon sx={{ width: 30, height: 30 }} />
							</IconButton>
						</Tooltip>
						<Tooltip title={activeFacilities?.length === 1 ? getFacilityName() : getTooltipTitle(facilities, activeFacilities, "all")}>
							<Chip
								label={<Typography
									textAlign={"center"}
									sx={{
										fontFamily: "Manrope",
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "10px",
										lineHeight: "14px",
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										maxWidth: "40px",
									}}
								>
									{activeFacilities?.length === 1 ? getFacilityName() : getTooltipTitle(facilities, activeFacilities, "all")}
								</Typography>}
								size="small"
								sx={{ ml: "-1px" }}
							/>
						</Tooltip>
					</>
				)}

				<CustomPopover
					arrow="left-center"
					anchorEl={facilityAnchorEl}
					open={facilityAnchorEl !== null}
					onClose={() => {
						setFacilityAnchorEl(null);
					}}
					PaperProps={{
						style: { width: '300px' },
					}}
				>
					<Grid sx={{ mt: 2, padding: 1 }} container direction="row" alignContent={"center"} display={"flex"} justifyContent={"space-between"}>
						<Grid item xs={5}>
							<Typography variant="h6">
								Facilities <Typography component="span">({facilities?.length || 0})</Typography>
							</Typography>
						</Grid >
						<Grid item xs={7}>
							<TextField
								fullWidth
								placeholder="Search facility"
								size="small"
								onChange={(e) => setSearchText(e.target.value)}
								value={searchText}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
					<Grid container direction="row" sx={{ ml: 1.8, mt: 0.5 }}>
						<Checkbox
							checked={selectAll}
							onChange={handleToggleAll}
							sx={{
								ml:"-11px",
								color: "white",
								"&.Mui-checked": {
									color:
										selectAll
											? "#2259c9"
											: "#2259c9"
									,
								},
								"& .MuiSvgIcon-root": { fontSize: 26 },
							}}
						/>
						<Typography sx={{ mt: 1 }}>Select / Clear All</Typography>
					</Grid>
					<Divider />
					<Scrollbar sx={{ height: 250, mt:1 }}>
						{facilities && Array.isArray(facilities) &&
							_.orderBy(facilities, "name", "ASC")?.filter(ele => ele.name?.toLowerCase().includes(searchText?.toLowerCase())).map((fa, index) => {
								return (
									<MenuItem
										selected={includes(activeFacilities, fa._id)}
										sx={{
											p: 0.4,
											py: 0.1,
											"&.Mui-selected": {
												background: "#2259c9",
												marginBottom: 0.5,
												borderRadius: 1,
												".MuiTypography-root": {
													color: "#fff !important",
												},
											},
										}}
									>
										<ListItemText key={index} onClick={() => handleChangeFacility(fa)}>
											<Checkbox
												checked={includes(selectedFacility, fa._id)}
												onChange={() => handleChangeFacility(fa)}
												sx={{
													color: "white",
													"&.Mui-checked": {
														color:
															includes(activeFacilities, fa._id) || includes(selectedFacility, fa._id)
																? includes(activeFacilities, fa._id)
																	? "#fff"
																	: "#2259c9"
																: "white",
													},
													"& .MuiSvgIcon-root": { fontSize: 26 },
												}}
											/>
											{fa.name}
										</ListItemText>
									</MenuItem>
								);
							})
						}
						{Array.isArray(facilities) && (facilities?.length === 0 || facilities?.filter(ele => ele.name?.toLowerCase().includes(searchText?.toLowerCase())).length === 0) &&
							<Typography sx={{ px: 1, py: 2, alignItems: "center" }}>No account found</Typography>
						}
					</Scrollbar>
					<Divider />
					<Stack direction={"row"} justifyContent={"center"} sx={{ p: 1 }}>
						<Button
							size="small"
							variant="contained"
							onClick={() => {
								setFacilityAnchorEl(null);
								handleFacilityRun();
							}}
						>
							Run
						</Button>
					</Stack>
				</CustomPopover>
				{/* {showFacilityList && Array.isArray(facilities) ? (
					<div className={`${styles.selectOptionsWrpr}`}>
						{facilities.map((fa, index) => {
							return (
								<p
									key={index}
									className={`df aic ffmar fs15 ${styles.selectionOption} ${styles.forFacility} ${includes(activeFacilities, fa._id) ? styles.selected : ""
										}`}
									onClick={() => handleChangeFacility(fa)}
								>
									<Checkbox
										checked={includes(selectedFacility, fa._id)}
										onChange={() => handleChangeFacility(fa)}
										sx={{
											color: "white",
											"&.Mui-checked": {
												color:
													includes(activeFacilities, fa._id) || includes(selectedFacility, fa._id)
														? includes(activeFacilities, fa._id)
															? "white"
															: "#2259c9"
														: "white",
											},
											"& .MuiSvgIcon-root": { fontSize: 26 },
										}}
									/>
									{fa.name}
								</p>
							);
						})}
						<Stack direction={"row"} justifyContent={"center"} spacing={2}>
							<Button size="small" variant="contained" onClick={handleFacilityRun}>
								Run
							</Button>
						</Stack>
					</div>
				) : undefined} */}
			</div >
			{isFacilitySelected && <NoFacilitySelectionDialog handleClose={() => setIsFacilitySelected(false)} />
			}
		</>
	);
};

export default FacilitySelection;
