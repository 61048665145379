/* eslint-disable array-callback-return */
import { CircularProgress, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import update from "immutability-helper";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CollapseSVG from "../../assets/svgs/collapse.svg";
import ExpandSVG from "../../assets/svgs/expand.svg";
import SearchSVG from "../../assets/svgs/search.svg";
import ThreeDots from "../../assets/svgs/three-dots.svg";
import axios from "../../axios";
import popupStyles from "../../popup.module.scss";
import { deletePatientData } from "../../services/adt.service";
import { getAccountQuestions } from "../../services/api/user.api";
import { firstAndLastDateOfInput, getCensusFirstDate } from "../../services/data-input.service";
import { AUTH_ROLES } from "../../types/auth.type";
import { expandedColumnsDefault as expandedColumnsDefaultDataInput } from "../../types/pages.type";
import { isOnlyHospitalTabAccess } from "../../utilis/common";
import { toDisplayTime, toSaveDate, toSaveEndDate } from "../../utilis/date-formats";
import AddPatient from "../landing/facility/data/add-patient/AddPatient";
import QuestionSetup from "../landing/facility/data/question-setup/QuestionSetup";
import ResidentDetails from "../landing/facility/data/residentDetails";
import PopupDialog from "../popupDialog/PopupDialog";
import Button from "../ui/button/Button";
import DropDown from "../ui/drop-down/DropDown";
import styles from "./DataInput.module.scss";
import EditBedCapacityDialog from "./edit-bed-capacity/EditBedCapacityDialog";
import EditCensusDialog from "./edit-census-dialog/EditCensusDialog";
import NoFacilityDialog from "./no-facility-dialog/NoFacilityDialog";
import Table from "./table/Table";
import { LoadingButton } from "@mui/lab";
import EditEndDateInputDialog from "./edit-bed-capacity/EditEndDateInputDialog";
import moment from "moment";


const DataInput = () => {
	const [expandedView, setExpandedView] = useState(false);
	const [loading, setLoading] = useState(true);
	const [type, setType] = useState("admission");
	const [patients, setPatients] = useState([]);
	const [fromDate, setFromDate] = useState(dayjs().subtract(1, "month").format("YYYY-MM-DD"));
	const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
	const [search, setSearch] = useState("");
	const { permission } = useSelector(({ permission }) => ({ permission }));
	const [isEndDateOfADT, setIsEndDateOfADT] = useState(false);

	const expandedColumnsDefault = useMemo(() => {
		if (permission?.isMultipleFacility) {
			return [
				{
					Header: "Facility",
					accessor: "facilityId.name",
					isDefault: true
				},
				...expandedColumnsDefaultDataInput,
			];
		}
		return [...expandedColumnsDefaultDataInput];
	}, [permission?.isMultipleFacility]);

	const [transferType, setTransferType] = useState("unplannedHospitalTransfer");
	const [patientsOriginal, setPatientsOriginal] = useState([]);
	const [selectedResident, setSelectedResident] = useState(undefined);
	const [showAdd, setShowAdd] = useState(false);
	const [showSettings, setShowSettings] = useState(false);
	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [showSetup, setShowSetup] = useState(false);
	const [hasInput, setHasInput] = useState(false);
	const [hasInputLoading, setHasInputLoading] = useState(true);
	const [startDate, setStartDate] = useState("");
	const [censusCount, setCensusCount] = useState("");
	const [bedCapacity, setBedCapacity] = useState(null);
	const [adtStepCount, setAdtStepCount] = useState(1);
	const [isEditCensus, setIsEditCensus] = useState(false);
	const [isEditBedCapacity, setIsEditBedCapacity] = useState(false);
	const [hasFacility, setHasFacility] = useState(true);
	const [isOpenNoFacility, setIsOpenNoFacility] = useState(false);
	const [expandedAdmissionsColumns, setExpandedAdmissionsColumns] = useState(expandedColumnsDefault);
	const [expandedReAdmissionsColumns, setExpandedReAdmissionsColumns] = useState(expandedColumnsDefault);
	const [expandedReturnsColumns, setExpandedReturnsColumns] = useState(expandedColumnsDefault);
	const { endDateOfADT } = useSelector((state) => state.common);

	const isOnlyHospitalDashboard = useMemo(() => {
		return isOnlyHospitalTabAccess();
	}, []);

	useEffect(() => {
		if (isOnlyHospitalDashboard) {
			setType("transfer");
		}
	}, [isOnlyHospitalDashboard]);

	useEffect(() => {
		if (endDateOfADT && moment(endDateOfADT).isAfter(moment(), 'day')) {
			setToDate(moment(endDateOfADT).format("YYYY-MM-DD"));
		}
	}, [endDateOfADT]);


	const types = useMemo(() => {
		if (!isOnlyHospitalDashboard) {
			return [
				{
					value: "admission",
					label: "Admission",
				},
				{
					value: "reAdmission",
					label: "Re-Admission",
				},
				{
					value: "return",
					label: "Hospital Return",
				},
				{
					value: "transfer",
					label: "Transfer",
				},
			];
		} else {
			return [
				{
					value: "return",
					label: "Hospital Return",
				},
				{
					value: "transfer",
					label: "Transfer",
				},
			];
		}
	}, [isOnlyHospitalDashboard]);

	const transferTypes = useMemo(() => {
		if (!isOnlyHospitalDashboard) {
			return [
				{
					value: "allTransfers",
					label: "All Transfers",
					subOption: false,
				},
				{
					value: "allHospitalTransfers",
					label: "All Hospital Transfers",
					subOption: false,
				},
				{
					value: "unplannedHospitalTransfer",
					label: "Unplanned Hospital Transfer",
					subOption: true,
				},

				{
					value: "plannedHospitalTransfer",
					label: "Planned Hospital Transfer",
					subOption: true,
				},
				{
					value: "allCommunityTransfers",
					label: "All Community Transfers",
					subOption: false,
				},
				{
					value: "safeDischarge",
					label: "Safe Discharge",
					subOption: true,
				},
				{ value: "SNF", label: "SNF", subOption: true },
				{ value: "AMA", label: "AMA", subOption: true },
				{ value: "deceased", label: "Deceased", subOption: false },
			];
		} else {
			return [
				{
					value: "allHospitalTransfers",
					label: "All Hospital Transfers",
					subOption: false,
				},
				{
					value: "unplannedHospitalTransfer",
					label: "Unplanned Hospital Transfer",
					subOption: true,
				},

				{
					value: "plannedHospitalTransfer",
					label: "Planned Hospital Transfer",
					subOption: true,
				},
			];
		}
	}, [isOnlyHospitalDashboard]);

	let transferTypesColumns = {};
	transferTypes.map((ele) => {
		transferTypesColumns[ele.value] = expandedColumnsDefault;
	});
	const [expandedAllTransferColumns, setExpandedAllTransferColumns] = useState(transferTypesColumns);

	const [deletePatientState, setDeletePatientState] = useState({
		state: false,
		patientId: null,
	});
	const [deletePatientLoading, setDeletePatientLoading] = useState(false);
	const { facilities: activeFacilities } = useSelector((state) => state.activeFacilities);
	const [initialDate, setInitialDate] = useState(null);
	const [defaultBedCapacity, setDefaultBedCapacity] = useState(0);
	const [firstAndLastData, setFirstAndLastData] = useState(null);
	const { auth } = useSelector(({ auth }) => ({ auth }));

	useEffect(() => {
		const fetchFirstAndLastData = async () => {
			const firstAndLastDataResponse = await firstAndLastDateOfInput({
				facilityIds: activeFacilities,
			});
			if (firstAndLastDataResponse) {
				setFirstAndLastData(firstAndLastDataResponse);
			}
		};
		fetchFirstAndLastData();
		// eslint-disable-next-line no-use-before-define
	}, [activeFacilities]);

	const getTooltipText = (text, count = 40, variant = "body2") => {
		return (
			<span>
				{text && text.length > count ? (
					<Tooltip title={text} arrow>
						<span style={{ cursor: "pointer" }}>{text.substring(0, count) + "..."}</span>
					</Tooltip>
				) : (
					<span style={{ cursor: "pointer" }}>{text || ""}</span>
				)}
			</span>
		);
	};

	const getQuestionAnswer = (question, row) => {
		const { accessor, type, validationType, multiple } = question;
		if (type === "text") {
			return row[accessor] ? getTooltipText(row[accessor]) : null;
		} else if (type === "toggle") {
			// eslint-disable-next-line eqeqeq
			return row[accessor] && row[accessor] != '' ? "Yes" : "No"
		} else if (type === "date") {
			return row[accessor] ? toDisplayTime(row[accessor], "MM/DD/YYYY") : "-";
		} else if (type === "time") {
			return row[accessor] ? row[accessor] : null
		} else if (type === "validation") {
			if (multiple) {
				const dxAnswer = row[validationType] ? row[validationType].map((prodData) => prodData.label).join(", ") : null;
				return dxAnswer ? getTooltipText(dxAnswer) : "N/A";
			} else {
				return row[validationType] ? getTooltipText(row[validationType]?.label) : "N/A";
			}
		}
	};

	const getTableColumns = (data, type, transferType = null) => {
		if (type === "transfer") {
			const questions = _.filter(data, {
				forType: type,
				forTransferType:
					transferType === "unplannedHospitalTransfer" || transferType === "plannedHospitalTransfer"
						? "hospitalTransfer"
						: transferType,
			});
			const dynamicColumns = questions.map((ele) => {
				return {
					Header: ele?.order?.tableLabel || ele?.order?.label || ele?.question?.tableLabel || ele?.question?.label,
					accessor: ele?.question?.accessor,
					Cell: ({ row = null }) => {
						if (row) {
							const transferValue = getQuestionAnswer(ele?.question, row.original);
							return transferValue ? transferValue : "N/A";
						} else {
							return "N/A";
						}
					},
				};
			});
			return dynamicColumns;
		} else {
			const questions = _.filter(data, { forType: type });
			const dynamicColumns = questions.map((ele) => {
				return {
					Header: ele?.order?.tableLabel || ele?.order?.label || ele?.question?.tableLabel || ele?.question?.label,
					accessor: ele?.question?.accessor,
					Cell: ({ row }) => getQuestionAnswer(ele?.question, row.original),
				};
			});
			return dynamicColumns;
		}
	};

	const getQuestions = async (activeFacilitiesData) => {
		const res = await getAccountQuestions({
			facilityid: activeFacilitiesData,
			accountId: auth.accountId,
		});
		if (res && res.length > 0) {
			//setDefaultQuestions(res);

			const admissionColumns = getTableColumns(res, "admission");
			// const expandedColumnsDefaultData = expandedColumnsDefault			
			setExpandedAdmissionsColumns([...expandedColumnsDefault, ...admissionColumns]);

			const reAdmissionColumns = getTableColumns(res, "admission");
			setExpandedReAdmissionsColumns([...expandedColumnsDefault, ...reAdmissionColumns]);

			const returnColumns = getTableColumns(res, "return");
			setExpandedReturnsColumns([...expandedColumnsDefault, ...returnColumns]);

			transferTypes.map(async (ele) => {
				if (ele.value === "unplannedHospitalTransfer" || ele.value === "plannedHospitalTransfer") {
					const transferColumnsHospital = getTableColumns(res, "transfer", "hospitalTransfer");
					await setExpandedAllTransferColumns((prev) => ({
						...prev,
						[ele.value]: [...expandedColumnsDefault, ...transferColumnsHospital],
					}));
				} else if (
					ele.value === "safeDischarge" ||
					ele.value === "SNF" ||
					ele.value === "AMA" ||
					ele.value === "deceased"
				) {
					const transferColumnsCommunity = getTableColumns(res, "transfer", ele.value);
					await setExpandedAllTransferColumns((prev) => ({
						...prev,
						[ele.value]: [...expandedColumnsDefault, ...transferColumnsCommunity],
					}));
				}
			});
			return;
		}
	};

	useEffect(() => {
		getQuestions(activeFacilities);
		// eslint-disable-next-line
	}, [activeFacilities]);

	const getInitialCensusDate = async () => {
		const facilityId = localStorage.getItem("facilityId");
		await getCensusFirstDate(facilityId).then((res) => {
			if (res) {
				setInitialDate(res?.date);
				setDefaultBedCapacity(res?.bedCapacity);
			}
		});
	};

	useEffect(() => {
		getInitialCensusDate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem("facilityId")]);

	useEffect(() => {
		if (activeFacilities.length > 0) {
			const timer = setTimeout(() => {
				if (
					(activeFacilities[activeFacilities.length - 1] === undefined ||
						activeFacilities[activeFacilities.length - 1] == null) &&
					(permission.role === AUTH_ROLES.SUPER ||
						permission.role === AUTH_ROLES.OWNER ||
						permission.role === AUTH_ROLES.REGIONAL)
				) {
					setHasFacility(false);
					setIsOpenNoFacility(true);
				}
				if (
					activeFacilities[activeFacilities.length - 1] === undefined ||
					activeFacilities[activeFacilities.length - 1] == null
				) {
					setHasFacility(false);
				}
			}, 1500);
			return () => clearTimeout(timer);
		}
	}, [permission, activeFacilities]);

	const hasInputAction = async () => {
		setHasInputLoading(true);
		if (activeFacilities.length > 0) {
			let re = await axios.get(`/api/facility/has-census/?facilityId=${activeFacilities[activeFacilities.length - 1]}`);
			if (re?.data) {
				setHasInput(re?.data);
			}
		}
		setHasInputLoading(false);
	};

	const toggleAdd = () => setShowAdd(!showAdd);

	const patientUpdated = (data) => {
		if (type.toLowerCase() === data?.type.toLowerCase()) {
			const idx = patients.findIndex((p) => p._id === data._id);
			const patientsUpdated = update(patients, {
				[idx]: {
					$set: data,
				},
			});
			setPatients(patientsUpdated);
		} else {
			setPatients(patients.filter((item) => item._id !== data._id));
		}
	};

	const getData = async (signal) => {
		setLoading(true);
		const facilityId = activeFacilities[activeFacilities.length - 1];
		if (facilityId) {
			let formDateLatest = toSaveDate(fromDate, "YYYY-MM-DD");
			let toDateLatest = toSaveEndDate(toDate, "YYYY-MM-DD");
			await axios
				.get(
					`/api/patient/${facilityId}`,
					{
						signal: signal,
						params: {
							activeFacilities: activeFacilities,
							type: type,
							fromdate: formDateLatest,
							todate: toDateLatest,
							...type === "transfer" && { transfertype: transferType },
						}
					}
				)
				.then((res) => {
					setLoading(false);
					if (res && res.data) {
						setPatients(res?.data);
						setPatientsOriginal(res?.data);
					}
				})
				.catch((e) => { });
		}
	};

	const deletePatient = async (id) => {
		const res = await deletePatientData(id);
		if (res && res?._id) {
			const newData = patients.filter((f) => f._id !== res?._id);
			setPatients(newData);
		}
	};

	useEffect(() => {
		const fetchData = async (signal) => {
			await getData(signal);
			await hasInputAction();
		};
		if (!type || !fromDate || !toDate || activeFacilities.length === 0) {
			setHasInputLoading(false);
			setLoading(false);
			setPatients([]);
			setPatientsOriginal([]);
			return;
		}
		const controller = new AbortController();
		const signal = controller.signal;
		fetchData(signal);
		return () => {
			controller.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		type,
		fromDate,
		toDate,
		transferType,
		// eslint-disable-next-line
		activeFacilities,
	]);

	// *********************
	// eslint-disable-next-line
	const columns = useMemo(() => {
		const baseColumns = [
			{
				Header: "Last Name",
				accessor: "lastName",
			},
			{
				Header: "First Name",
				accessor: "firstName",
			},
			{
				Header: "Middle Initial",
				accessor: "middleInitial",
			},
			{
				Header: "Type",
				accessor: "type",
				Cell: ({ row }) => (
					<div>
						{row.original.type === "transfer"
							? row.original.transferType.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
								return str.toUpperCase();
							})
							: row.original.type.replace(/^./, function (str) {
								return str.toUpperCase();
							})}
					</div>
				),
			},
			{
				Header: "Date of transfer",
				accessor: "dateOfADT",
				Cell: ({ row }) => <div>{toDisplayTime(row.original.dateOfADT)}</div>,
			},
		];

		if (permission?.isMultipleFacility) {
			return [{
				Header: "Facility",
				accessor: "facilityId.name",
				isDefault: true
			}, ...baseColumns];
		}

		return baseColumns;
	}, [permission?.isMultipleFacility]);

	useEffect(() => {
		let searchWords = search
			?.replace(/[^\w\s]/gi, "")
			?.split(" ")
			?.filter((word) => word.length > 0);
		let newData = update(patientsOriginal, {
			$set: patientsOriginal.filter((patient) => {
				if (search === "") {
					return true;
				}
				let found = false;

				if (
					(patient?.firstName
						?.replace(/[^\w\s]/gi, "")
						?.toLowerCase()
						.includes(searchWords[0]?.toLowerCase()) &&
						(searchWords.length === 1
							? true
							: patient.lastName
								?.replace(/[^\w\s]/gi, "")
								?.toLowerCase()
								.includes(searchWords[1]?.toLowerCase()))) ||
					(patient.lastName
						?.replace(/[^\w\s]/gi, "")
						?.toLowerCase()
						.includes(searchWords[0]?.toLowerCase()) &&
						(searchWords.length === 1
							? true
							: patient?.firstName
								?.replace(/[^\w\s]/gi, "")
								?.toLowerCase()
								.includes(searchWords[1]?.toLowerCase()))) ||
					(patient.lastName
						?.replace(/[^\w\s]/gi, "")
						?.toLowerCase()
						.includes(searchWords[0]?.toLowerCase()) &&
						(searchWords.length === 1
							? true
							: patient.middleInitial
								?.replace(/[^\w\s]/gi, "")
								?.toLowerCase()
								.startsWith(searchWords[1]?.toLowerCase()[0]))) ||
					(patient.middleInitial
						?.replace(/[^\w\s]/gi, "")
						?.toLowerCase()
						.startsWith(searchWords[0]?.toLowerCase()[0]) &&
						(searchWords.length === 1
							? true
							: patient.lastName
								?.replace(/[^\w\s]/gi, "")
								?.toLowerCase()
								.includes(searchWords[1]?.toLowerCase()))) ||
					(patient.firstName
						?.replace(/[^\w\s]/gi, "")
						?.toLowerCase()
						.includes(searchWords[0]?.toLowerCase()) &&
						(searchWords.length === 1
							? true
							: patient.middleInitial
								?.replace(/[^\w\s]/gi, "")
								?.toLowerCase()
								.startsWith(searchWords[1]?.toLowerCase()[0]))) ||
					(patient.middleInitial
						?.replace(/[^\w\s]/gi, "")
						?.toLowerCase()
						.startsWith(searchWords[0]?.toLowerCase()[0]) &&
						(searchWords.length === 1
							? true
							: patient.firstName
								?.replace(/[^\w\s]/gi, "")
								?.toLowerCase()
								.includes(searchWords[1]?.toLowerCase())))
				) {
					found = true;
				}
				return found;
			}),
		});

		setPatients(newData);
		// eslint-disable-next-line
	}, [search]);

	const patientAdded = (patient) => {
		if (type && type.toLowerCase() === patient?.type.toLowerCase()) {
			const newData = update(patients, {
				$push: [patient],
			});
			setPatients(newData);
			setPatientsOriginal(newData);
		}
	};

	const getDate = () => {
		return ` ${dayjs(startDate).add(-1, "day").endOf("day").format("MMMM DD YYYY hh:mm A")}`;
	};

	const saveCensus = async () => {
		setHasInputLoading(true);
		let re = await axios.post(`/api/census`, {
			count: !isOnlyHospitalTabAccess() ? censusCount : null,
			bedCapacity: bedCapacity,
			date: toSaveDate(startDate, "YYYY-MM-DD"),
			accountId: localStorage.getItem("accountId"),
			facilityId: activeFacilities[activeFacilities.length - 1],
			isBaseline: true,
		});
		setHasInput(re.data);
		setHasInputLoading(false);
		getInitialCensusDate();
	};

	const saveInputDateNext = useCallback((count) => {
		setAdtStepCount(count);
	}, []);

	const saveInputDatePrev = useCallback((count) => {
		setAdtStepCount(count);
	}, []);

	const cancelConfirmationDialog = () => {
		setAdtStepCount(1);
		setShowConfirmationDialog(false);
	};

	const onSaveConfirmDialog = () => {
		setShowConfirmationDialog(false);
		saveCensus();
	};

	const handleDelete = useCallback((patientId) => {
		setDeletePatientState({ state: true, patientId });
	}, []);

	const getTransferTypesColumn = () => {
		if (type === "transfer") {
			return expandedAllTransferColumns[transferType];
		}
	};
	function getTableColumnsForDetails() {
		if (type === "transfer") {
			if (transferType === "allTransfers" || transferType === "allHospitalTransfers") {
				const dateOfADTColumn = {
					Header: "Date of ADT",
					accessor: "dateOfADT",
					Cell: ({ row }) => <div>{toDisplayTime(row.original.dateOfADT)}</div>,
				};
				return [...expandedColumnsDefaultDataInput, dateOfADTColumn];
			} else {
				return expandedAllTransferColumns[transferType];
			}
		} else if (type === "admission") {
			return expandedAdmissionsColumns;
		} else if (type === "reAdmission") {
			return expandedReAdmissionsColumns;
		} else if (type === "return") {
			return expandedReturnsColumns;
		} else {
			return {};
		}
	}

	return (
		<>
			<div className={`page-content ${styles.dataInput}`}>
				<div className={`df aic ${styles.header}`}>
					<div className={`df aic ${styles.searchWrpr}`}>
						<input
							type="text"
							className={styles.searchInpt}
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Search"
						/>
						<SearchSVG fill="#4879F5" height="18" width="18" />
					</div>
					<DropDown
						options={types}
						selected={type}
						setOption={setType}
						style={{ minWidth: "120px", maxWidth: "160px" }}
					/>
					{type === "transfer" ? (
						<DropDown width={250} options={transferTypes} selected={transferType} setOption={setTransferType} />
					) : undefined}

					{/* <DateRange /> */}
					<input
						className={`m-l-20 m-r-20 ffmar fs13 ${styles.dateInput}`}
						type="date"
						value={fromDate}
						onChange={(e) => setFromDate(e.target.value)}
					/>
					<input
						className={`ffmar fs13 ${styles.dateInput}`}
						type="date"
						value={toDate}
						onChange={(e) => setToDate(e.target.value)}
					/>

					<div
						className={`c-pointer m-l-15`}
						onClick={() => {
							setExpandedView(!expandedView);
						}}
					>
						<div style={{ height: "60px" }}>
							{expandedView ? <CollapseSVG fill="#4879f5" height="66" /> : <ExpandSVG height="66" fill="#4879f5" />}
						</div>
					</div>

					<div className={`df aic mla`}>
						<button
							className={`df acc ${styles.roundBtn}`}
							onClick={(e) => {
								e.stopPropagation();
								setShowSettings(!showSettings);
							}}
						>
							<ThreeDots fill="#4879f5" height="66px" />
							<div className={`${styles.settingsFlyout} ${showSettings ? styles.active : ""}`}>
								<div
									onClick={(e) => {
										e.stopPropagation();
										setShowSettings(!showSettings);
										setShowSetup(!showSetup);
									}}
									className={`ffmar fs14 ${styles.settingsFlyoutItem}`}
								>
									Customize questions
								</div>
								{!isOnlyHospitalDashboard && !permission.isMultipleFacility && (
									<div
										onClick={(e) => {
											e.stopPropagation();
											setShowSettings(!showSettings);
											setIsEditCensus(!isEditCensus);
										}}
										className={`ffmar fs14 ${styles.settingsFlyoutItem}`}
									>
										Edit census
									</div>
								)}
								{!permission.isMultipleFacility && (
									<div
										onClick={(e) => {
											e.stopPropagation();
											setShowSettings(!showSettings);
											setIsEditBedCapacity(!isEditBedCapacity);
										}}
										className={`ffmar fs14 ${styles.settingsFlyoutItem}`}
									>
										Edit bed capacity
									</div>
								)}

								<div
									onClick={(e) => {
										e.stopPropagation();
										setShowSettings(!showSettings);
										setIsEndDateOfADT(!isEndDateOfADT);
									}}
									className={`ffmar fs14 ${styles.settingsFlyoutItem}`}
								>
									Edit end of ADT date
								</div>

							</div>
						</button>

						{showSetup ? <QuestionSetup close={() => setShowSetup(!showSetup)} /> : undefined}
						{permission.isMultipleFacility ? (
							<Tooltip
								title="this feature is disabled when viewing multiple facilities"
							>
								<LoadingButton
									size="large"
									variant="contained"
									sx={{
										padding: "0px 50px",
										height: "54px",
										borderRadius: "8px",
										fontSize: "15px",
										letterSpacing: "0.3px",
										background: "#4879F5",
										color: "#fff",
										opacity: 0.5,
										"&:hover": {
											boxShadow: "0px 0px 14px 0px rgb(0 0 0 / 20%)",
											color: "transparentize($color: #fff, $amount: 0.1)",
											background: "#4879F5"
										}
									}}
								>
									Add ADT
								</LoadingButton>
							</Tooltip>
						) : (
							<Button
								size="large"
								buttonStyle="theme"
								text={`Add ADT`}
								action={() => setShowAdd(true)}
								disabled={!hasInput}
								className={styles.addButton}
							/>
						)}

					</div>
				</div>
				<div className={styles.tableWrapper}>
					{!loading && activeFacilities.length > 0 && (
						<div className={styles.listWrpr}>
							{!hasInputLoading ? (
								hasInput ? (
									<Table
										isMultipleFacility={permission?.isMultipleFacility}
										columns={
											!expandedView
												? columns
												: type === "admission" || type === "reAdmission"
													? type === "reAdmission"
														? expandedReAdmissionsColumns
														: expandedAdmissionsColumns
													: type === "return"
														? expandedReturnsColumns
														: getTransferTypesColumn()
										}
										loading={loading}
										data={patients}
										initialDate={initialDate}
										setSelectedResident={setSelectedResident}
										selectedResident={selectedResident}
										patientUpdated={patientUpdated}
										handleDelete={handleDelete}
									/>
								) : (
									<div className={`df acc ffc ${styles.addCensusStartWrpr}`}>
										{adtStepCount === 1 && (
											<>
												<p className={`ffmar fw600 fs18`}>
													Before you can add ADT, we need to know the date for which you will start entering ADT data.
												</p>
												<div className={`df ffc acc`}>
													<input
														type="date"
														className={`ffmar fs13 m-t-25 ${styles.dateInput}`}
														value={startDate}
														disabled={!hasFacility}
														onChange={(e) => {
															setStartDate(e.target.value);
														}}
													/>
													<div className={`w100 m-t-20`}>
														<Button
															text="Next"
															buttonStyle="theme"
															size="large"
															w100={true}
															disabled={!startDate || !hasFacility}
															className={styles.censusButton}
															action={() => saveInputDateNext(isOnlyHospitalDashboard ? 3 : 2)}
														/>
													</div>
												</div>
											</>
										)}
										{adtStepCount === 2 && (
											<>
												<p className={`ffmar fw600 fs18`}>
													Now Please enter your facility's census as of
													{getDate()}.
												</p>
												<div className={`df ffc acc`}>
													<input
														className={`tac m-t-30 ${styles.censusInput}`}
														value={censusCount}
														onChange={(e) => {
															const re = /^[0-9\b]+$/;
															if (e.target.value === "" || re.test(e.target.value)) {
																setCensusCount(e.target.value);
															}
														}}
													/>
													<div className={`w100 m-t-20 ${styles.censusButtonContainer}`}>
														<Button
															text="Back"
															buttonStyle="primary"
															size="large"
															className={styles.censusButtonPrev}
															action={() => saveInputDatePrev(1)}
														/>
														<Button
															text="Next"
															buttonStyle="theme"
															size="large"
															disabled={!censusCount}
															className={styles.censusButtonSave}
															action={() => saveInputDatePrev(3)}
														/>
													</div>
												</div>
											</>
										)}
										{adtStepCount === 3 && (
											<>
												<p className={`ffmar fw600 fs18`}>What is the bed capacity of your facility?</p>
												<div className={`df ffc acc`}>
													<input
														className={`tac m-t-30 ${styles.censusInput}`}
														value={bedCapacity}
														onChange={(e) => {
															const re = /^[0-9\b]+$/;
															if (e.target.value === "" || re.test(e.target.value)) {
																setBedCapacity(e.target.value);
															}
														}}
													/>
													<div className={`w100 m-t-20 ${styles.censusButtonContainer}`}>
														<Button
															text="Back"
															buttonStyle="primary"
															size="large"
															className={styles.censusButtonPrev}
															action={() => saveInputDatePrev(isOnlyHospitalDashboard ? 1 : 2)}
														/>
														<Button
															text="Save"
															buttonStyle="theme"
															size="large"
															disabled={!bedCapacity}
															className={styles.censusButtonSave}
															action={() => setShowConfirmationDialog(true)}
														/>
													</div>
												</div>
											</>
										)}
									</div>
								)
							) : (
								<div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
									<CircularProgress />
								</div>
							)}
						</div>
					)}
				</div>

				{isOpenNoFacility && (
					<NoFacilityDialog close={() => setIsOpenNoFacility(false)} patientAdded={patientAdded} />
				)}

				{showAdd ? <AddPatient
					initialDate={initialDate}
					close={toggleAdd}
					patientAdded={patientAdded}
					isMultipleFacility={permission?.isMultipleFacility}
				/> : undefined}

				{selectedResident ? (
					<ResidentDetails
						details={selectedResident}
						close={() => setSelectedResident(undefined)}
						tableColumns={getTableColumnsForDetails()}
					/>
				) : undefined}
			</div>
			{isEditCensus && <EditCensusDialog close={() => setIsEditCensus(false)} />}
			{isEditBedCapacity && (
				<EditBedCapacityDialog
					setDefaultBedCapacity={setDefaultBedCapacity}
					bedCapacity={defaultBedCapacity}
					close={() => setIsEditBedCapacity(false)}
				/>
			)}
			{isEndDateOfADT && (
				<EditEndDateInputDialog
					endDateOfADT={endDateOfADT}
					close={() => setIsEndDateOfADT(false)}
					firstAndLastData={firstAndLastData}
					activeFacilities={activeFacilities}
				/>
			)}
			{showConfirmationDialog && (
				<>
					<div className={popupStyles.overlay}></div>
					<div className={popupStyles.addPopup}>
						<div className={`df aic ${popupStyles.hdr}`}>
							<p className={`ffmm fs18`}>Confirmation</p>
						</div>{" "}
						{!isOnlyHospitalTabAccess() ? (
							<div className={`${popupStyles.popupCntnt} ${styles.popupCntnt}`}>
								Are you sure you want to set{" "}
								<span className={styles.importantSpan}>{dayjs(startDate).format("MM/DD/YYYY")}</span> as starting ADT
								date and <br />
								<span className={styles.importantSpan}>{censusCount}</span> as the census count with{" "}
								<span className={styles.importantSpan}>{bedCapacity}</span> bad capacity ?
							</div>
						) : (
							<div className={`${popupStyles.popupCntnt} ${styles.popupCntnt}`}>
								Are you sure you want to set{" "}
								<span className={styles.importantSpan}>{dayjs(startDate).format("MM/DD/YYYY")}</span> as starting ADT
								date and <br />
								<span className={styles.importantSpan}>{bedCapacity}</span>
								{" bad capacity ?"}
							</div>
						)}
						<div className={`${popupStyles.popupFtr}`}>
							<div className={`df aic ${popupStyles.actionsWrrp}`}>
								<div className={`mla`}>
									<button className={`ffmr fs14 m-r-8 ${popupStyles.text}`} onClick={cancelConfirmationDialog}>
										Cancel
									</button>
									<button className={`ffmr fs14 ${popupStyles.primary}`} onClick={onSaveConfirmDialog}>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			)}

			<PopupDialog
				state={deletePatientState.state}
				isDestructiveAction={true}
				isLoading={deletePatientLoading}
				onClose={() => setDeletePatientState({ state: false, patientId: null })}
				onConfirm={async () => {
					setDeletePatientLoading(true);
					await deletePatient(deletePatientState.patientId);
					setDeletePatientLoading(false);
					setDeletePatientState({ state: false, patientId: null });
				}}
				dialogMessage={"Are you sure you want to delete this ADT?"}
				confirmBtnText={"Delete"}
			/>
		</>
	)
};

export default DataInput;
