import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HashRouter } from "react-router-dom";
import "./App.scss";
import useTimeLogs from "./components/hooks/useTimeLogs";
import NotificationList from "./components/notification/NotificationList";
import InactivityLogout from "./components/shared/InactivityLogout";
import AppContext from "./context/AppContext";
import Routes from "./router/routes";
import ThemeProvider from "./theme";
import { Auth0Provider } from "@auth0/auth0-react";
import authConfig from "./auth_config";

function App() {
	const { auth, notification } = useSelector(({ auth, notification }) => ({
		auth,
		notification,
	}));

	const [jwt, setJwt] = useState(localStorage.getItem("x-auth"));
	const contextVals = { jwt, setJwt };	
	useTimeLogs({ accountId: localStorage.getItem("accountId") || auth?.accountId });

	useEffect(() => {
		const onError = (e) => {
			if (e.message === "ResizeObserver loop limit exceeded") {
				const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
				const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
				if (resizeObserverErr) {
					resizeObserverErr.setAttribute("style", "display: none");
				}
				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute("style", "display: none");
				}
			}
		};
		window.addEventListener("error", onError);

		return () => {
			window.removeEventListener("error", onError);
		};
	}, []);

	// useEffect(() => {
	// 	if (!auth?._id) return;
	// 	// Create random string for session id
	// 	const sessionId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

	// 	let interval = setInterval(() => {
	// 		let countdown = parseInt(localStorage.getItem("countdown"));
	// 		countdown += 1;
	// 		localStorage.setItem("countdown", countdown);
	// 		axios.post("/api/log/time", {
	// 			userSessionId: sessionId,
	// 			userId: auth?._id,
	// 		});
	// 		if (countdown >= 10) {
	// 			clearInterval(interval);
	// 		}
	// 	}, 1000 * 60);

	// 	axios.post("/api/log/time", {
	// 		userSessionId: sessionId,
	// 		userId: auth?._id,
	// 		// facilityId
	// 		accountId: auth?.accountId,
	// 	});
	// }, [auth]);

	useEffect(() => {
		localStorage.setItem("countdown", "0");
		let openTabs = localStorage.getItem("openTabs");
		if (!openTabs || openTabs === "NaN" || openTabs <= 0) {
			localStorage.setItem("openTabs", 1);
		} else {
			localStorage.setItem("openTabs", parseInt(openTabs) + 1);
		}
		window.onbeforeunload = () => {
			let localOpenTabs = localStorage.getItem("openTabs");
			localStorage.setItem("openTabs", parseInt(localOpenTabs) - 1);
		};
		const setCountdownTo0 = () => {
			localStorage.setItem("countdown", "0");
		};

		window.addEventListener("click", setCountdownTo0);

		return () => {
			window.removeEventListener("click", setCountdownTo0);
		};
	}, []);

	return (
		<ThemeProvider>
			<Auth0Provider
				domain={authConfig.domain}
				clientId={authConfig.clientId}
				redirectUri={authConfig.redirectUri}
			>
				<HashRouter>
					<AppContext.Provider value={contextVals}>
						<InactivityLogout />
						<Routes jwt={jwt} />
					</AppContext.Provider>
				</HashRouter>
				<NotificationList notifications={notification} />
			</Auth0Provider>
		</ThemeProvider>
	);
}

export default App;
