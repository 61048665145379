const CompareSideIcon = () => {
  return (
    <svg
      width="19"
      height="23"
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.40904 2.09093H2.18175C1.02654 2.09093 0.0908203 3.02659 0.0908203 4.18185V18.8182C0.0908203 19.9734 1.02649 20.9091 2.18175 20.9091H7.40904V23H9.49997V0H7.40904V2.09093ZM7.40904 17.7728H2.18175L7.40904 11.5V17.7728ZM16.8181 2.09093H11.5908V4.18185H16.8181V17.7728L11.5908 11.5V20.9091H16.8181C17.9733 20.9091 18.9091 19.9735 18.9091 18.8182V4.1818C18.9091 3.02659 17.9733 2.09093 16.8181 2.09093Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CompareSideIcon;
