import api from './api';

const API_PREFIX = "api/patient";

const deletePatient = async (id) => {
    const response = await api.delete(`${API_PREFIX}/${id}`);
    return response;
};

export {
    deletePatient
};
