import moment from "moment-timezone";
// import { store } from '..';
// import dayjs from "dayjs";

export function dateFormat(date, format = "MM/DD/YYYY") {
	return date ? moment(date).format(format) : null;
}

export function dateFormatStartDay(date, format = "MM/DD/YYYY") {
	return moment(date, format).startOf("day").format();
}

export function dateStartDate(date, format = null) {
	if (format) {
		return moment(date, format).startOf("day").format();
	} else {
		return moment(date).startOf("day").format();
	}
}

export function isCompleteMonth(utcDt, dtFormat = "MM/DD/YYYY") {
	if (utcDt) {
		return moment.utc(utcDt).local().format(dtFormat);
	} else {
		return null;
	}
}

export function toSaveDate(date, format = "MM/DD/YYYY") {
	const newDateOfADT = moment(date, format).format("YYYY-MM-DD");
	const latestStringDate = moment.utc(newDateOfADT).toISOString();
	return latestStringDate;
}

export function toSaveEndDate(date, format = "MM/DD/YYYY") {
	const newDateOfADT = moment(date, format).format("YYYY-MM-DD");
	const latestStringDate = moment.utc(newDateOfADT).endOf("day").toISOString();
	return latestStringDate;
}

export function toDisplayTime(date, format = "MM/DD/YYYY") {
	return moment.utc(date).format(format);
}

export function toDisplayFullDate(date) {
	return moment.utc(date).toDate();
}

export function toDisplayUTCDate(date) {
	return moment(toDisplayTime(date), "MM/DD/YYYY").toDate();
}

export function filterBodyDate(body) {
	body = body?.filter
		? Object.assign({}, body, {
				timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				filter: {
					startDate: toSaveDate(body?.filter.startDate),
					endDate: toSaveEndDate(body?.filter.endDate),
				},
		  })
		: {};
	return body;
}
