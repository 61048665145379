import ClearIcon from "@mui/icons-material/Clear";
import {
	Badge,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControlLabel,
	FormGroup,
	Popover,
	Tooltip,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import classNames from "classnames";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import CompareIconCircle from "../../assets/svgs/CompareIconCircle.svg";
import CompareIconClose from "../../assets/svgs/CompareIconClose.svg";
import CompareSideIcon from "../../assets/svgs/CompareSideIcon.svg";
import { getAccountFacility } from "../../services/facility.service";
import {
	setActiveComparisonAccountId,
	setActiveComparisonFacilitiesId,
	setcomparingAgainstDifferentFacility,
	setComparisonRanges,
	setRefetchComparison,
	setRemoveCompareFilter,
} from "../../store/reducers/comparisonReducers/comparison.slice";
import { areArraysDifferent } from "../../utilis/common";
import HtmlTooltip from "../shared/HtmlTooltip";
import styles from "./ComparisonPopup.module.scss";
import useComparisonFunctions from "./useComparisonFunctions";


const ComparisonPopup = ({ forPage, loading, setLoadingComparison }) => {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const { comparisonRanges: ranges, rangesSet, resetComparison } = useSelector((state) => state.comparison);
	const [filter, setFilter] = useState(ranges);
	const [gettingFacility, setGettingFacility] = useState(false);
	const anchorRef = useRef(null);
	const { getAllDataCount } = useComparisonFunctions({ forPage, setLoadingComparison });
	const facilityId = localStorage.getItem("facilityId");
	const accountId = localStorage.getItem("accountId");
	const { account: accounts } = useSelector(({ auth, account, permission }) => ({
		auth,
		account,
		permission,
	}));
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);

	// managing facilities for comparison
	const activeComparisonFacilities = useSelector((state) => state.comparison.activeComparisonFacilitiesId);
	const [localActiveComparisonFacilitiesId, setLocalActiveComparisonFacilitiesId] = useState([]);
	const [localFacilitiesId, setLocalFacilitiesId] = useState([]);

	// managing active account for comparison
	const activeAccountId = useSelector((state) => state.comparison.activeComparisonAccountId);
	const [localActveAccountId, setLocalActiveAccountId] = useState([]);

	const handleClose = () => {
		setLocalActiveComparisonFacilitiesId([]);
		setLocalFacilitiesId([]);
		setLocalActiveAccountId(null);
		setOpen(false);
	};

	const handleCalendarRangeChange = (item) => {
		const newRange = {
			...item.selection,
			startDate: new Date(item.selection.startDate),
			endDate: new Date(item.selection.endDate),
		};
		setFilter([newRange]);
	};

	const toggleOpenState = () => {
		setOpen((open) => !open);
	};

	const onSaveButton = () => {
		const diffFacilities = areArraysDifferent(localActiveComparisonFacilitiesId, activeFacilities);
		diffFacilities
			? dispatch(setcomparingAgainstDifferentFacility(true))
			: dispatch(setcomparingAgainstDifferentFacility(false));
		dispatch(setActiveComparisonAccountId(localActveAccountId));
		dispatch(setActiveComparisonFacilitiesId(localActiveComparisonFacilitiesId));
		dispatch(setComparisonRanges(filter));

		dispatch(setRefetchComparison(true));
		setOpen(false);
	};

	const handleOnChangeAccount = useCallback(
		async (accountId) => {
			setGettingFacility(true);
			setLocalActiveAccountId(accountId);
			const res = await getAccountFacility(accountId);

			if (res && res.length > 0) {
				setLocalFacilitiesId([...res]);
			}
			setGettingFacility(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setLocalActiveComparisonFacilitiesId]
	);

	const facilityCheckboxOnChange = useCallback((e, item) => {
		const checked = e.target.checked;

		if (checked) {
			setLocalActiveComparisonFacilitiesId((oldState) => {
				return [...oldState, item._id];
			});
		} else {
			setLocalActiveComparisonFacilitiesId((oldState) => {
				return oldState.filter((x) => x !== item._id);
			});
		}
	}, []);

	// initial account loadin
	useEffect(() => {
		if (activeAccountId && !localActveAccountId && open) {
			setLocalActiveAccountId(activeAccountId);
			handleOnChangeAccount(activeAccountId);
		} else if (!activeAccountId && localActveAccountId && open) {
			dispatch(setActiveComparisonAccountId(localActveAccountId));
			setLocalActiveComparisonFacilitiesId(activeFacilities);
			handleOnChangeAccount(localActveAccountId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeAccountId, activeComparisonFacilities, activeFacilities, dispatch, localActveAccountId, open]);

	useEffect(() => {
		setFilter(ranges);
	}, [ranges]);

	useEffect(() => {
		handleOnChangeAccount(accountId);
		// we want those facilities fetched only when initially the popup opens.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const filterUpdated = ranges ? ranges[0] : null;
		if (filterUpdated && filterUpdated.startDate && localStorage.getItem("facilityId")) {
			rangesSet && getAllDataCount && getAllDataCount(filterUpdated);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ranges, facilityId, activeFacilities, activeComparisonFacilities]);

	const handleCloseDateRange = useCallback(() => {
		dispatch(setRemoveCompareFilter(false));
	}, [dispatch]);

	// initial facilities loadin
	useEffect(() => {
		if (activeComparisonFacilities?.length > 0) {
			setLocalActiveComparisonFacilitiesId(activeComparisonFacilities);
		} else {
			setLocalActiveComparisonFacilitiesId(activeFacilities);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeComparisonFacilities, localActveAccountId]);

	useEffect(() => {
		if (resetComparison) {
			dispatch(setActiveComparisonAccountId(null));
			handleCloseDateRange();
		}
	}, [dispatch, handleCloseDateRange, resetComparison]);
	
	return (
		<>
			{rangesSet ? (
				<Badge
					color={!loading ? "error" : undefined}
					sx={{ cursor: loading ? "default" : "pointer" }}
					badgeContent={
						!loading ? (
							<HtmlTooltip content="Remove Compare Filter" placement="bottom-start">
								<ClearIcon
									onClick={handleCloseDateRange}
									sx={{
										fontSize: 12,
									}}
								/>
							</HtmlTooltip>
						) : (
							<div className="skeleton" style={{ width: "24px", height: "20px", borderRadius: "15px" }}></div>
						)
					}
				>
					<Tooltip title={"Compare & Highlight"} arrow>
						<>
							<div
								className={`${styles.popupOpenerWrapper} ${styles.selected} ${loading && "skeleton"}`}
								ref={anchorRef}
								style={{ cursor: loading ? "default" : "pointer" }}
								onClick={!loading && toggleOpenState}
							>
								{loading ? <div style={{ width: "23px", height: "33px" }} /> : <CompareSideIcon />}

							</div>
						</>
					</Tooltip>
				</Badge>
			) : (
				<Tooltip title="Compare & Highlight" arrow>
					<div
						className={`${styles.popupOpenerWrapper} ${loading && "skeleton"}`}
						ref={anchorRef}
						style={{ cursor: loading ? "default" : "pointer" }}
						onClick={!loading && toggleOpenState}
					>
						{loading ? <div style={{ width: "23px", height: "33px" }} /> : <CompareSideIcon />}
					</div>
				</Tooltip>
			)}

			<Popover
				id="comparison-popover"
				open={open}
				anchorEl={anchorRef.current}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box className={styles.mainWrapper}>
					<Box className={styles.headerWrapper}>
						<div className={styles.header}>
							<div className={styles.compareIconInnerWrapper}>
								<CompareIconCircle />
							</div>
							<Typography variant="h6">Compare and Highlight</Typography>
						</div>
						<div className={styles.compareIconCloseWrapper} onClick={handleClose}>
							<CompareIconClose />
						</div>
					</Box>
					<Box className={styles.accountsWrapper}>
						<Typography>Accounts:</Typography>
						<div className={styles.accounts}>
							{accounts?.length > 0 &&
								accounts.map((item, index) => {
									return (
										<Fragment key={item._id}>
											<Button
												key={`account-button-${index}`}
												className={`${item._id === localActveAccountId ? "selected" : null}`}
												onClick={() => handleOnChangeAccount(item._id)}
												variant="contained"
												sx={{
													mr: 1,
													mb: 1,
													background: "#eef3f7",
													color: "#000",
													cursor: "pointer",
													"&:hover": {
														color: "#fff",
													},
													"&.selected": {
														color: "#fff",
														background: "#103996",
													},
												}}
											>
												{item.name}
											</Button>
										</Fragment>
									);
								})}
							<Button
								onClick={() => setLocalActiveAccountId(1)}
								variant="contained"
								size="medium"
								sx={{
									mr: 1,
									mb: 1,
									background: "#eef3f7",
									color: "#000",
									cursor: "pointer",
								}}
							>
								Chose All Accounts
							</Button>
						</div>
					</Box>
					<Divider />
					<Box className={styles.contentWrapper}>
						<Box className={styles.facilitiesWrapper}>
							<Typography sx={{ marginBottom: "20px" }} fontSize={15}>
								Account Facilities:
							</Typography>
							<div className={styles.checkboxWrapper}>
								<Box sx={{ display: "flex", justifyContent: "center" }}>
									{gettingFacility && <CircularProgress />}
									{!gettingFacility &&
										localActiveComparisonFacilitiesId.length === 0 &&
										localFacilitiesId.length === 0 && <Typography>No facilities</Typography>}
								</Box>
								<FormGroup>
									{!gettingFacility &&
										localFacilitiesId?.length > 0 &&
										localFacilitiesId.map((item, index) => {
											const isChecked = localActiveComparisonFacilitiesId.find((facilityId) => {
												return facilityId === item._id;
											});
											return (
												<FormControlLabel
													key={`facility-chk-${index}`}
													control={<Checkbox checked={isChecked} onChange={(e) => facilityCheckboxOnChange(e, item)} />}
													label={item?.name}
												/>
											);
										})}
								</FormGroup>
							</div>
						</Box>
						<Box className={classNames(styles.calendarWrapper, "no-sidebar-calendar")}>
							<div className={styles.buttonContainer}>
								<div className={styles.saveButton}>
									<Button onClick={onSaveButton} variant="contained" size="small">
										Save
									</Button>
								</div>
								<div className={styles.cancelButton}>
									<Button onClick={handleClose} className={styles.saveButton} variant="outlined" size="small">
										Cancel
									</Button>
								</div>
							</div>
							<DateRangePicker
								ranges={filter}
								onChange={handleCalendarRangeChange}
								direction="horizontal"
								staticRanges={[]}
								showSelectionPreview={false}
								showDateDisplay={false}
								retainEndDateOnFirstSelection={true}
								months={2}
								inputRanges={[]}
							/>
						</Box>
					</Box>
				</Box>
			</Popover>
		</>
	);
};

export default ComparisonPopup;
