import ClearIcon from "@mui/icons-material/Clear";
import { Button, Input, Popover, Tooltip, Typography } from "@mui/material";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PickProjectionDaysSVG from "../../../assets/svgs/PickProjectionDaysSVG.svg";
import { setProjectionDays } from "../../../store/reducers/common.slice";
import { ADD_NOTIFICATION } from "../../../store/types";
import styles from "./PickProjectionDaysPopup.module.scss";

const PickProjectionDaysPopup = ({ open, setOpen, loading, style }) => {
	const { projectionDays } = useSelector((state) => state.common);
	const [value, setValue] = useState(null);
	const dispatch = useDispatch();
	const anchorRef = useRef(null);
	const initialOpenedFlag = useRef(false);
	const firstSetvalueFlag = useRef(false);

	const onApplyValue = () => {
		const number = Number(value);

		if (value === null || !isNaN(number)) {
			dispatch(setProjectionDays(value === null ? null : number));
		} else {
			dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					label: "Invalid number for projection days",
					type: "error",
					id: "deceasedSaveFilterError",
				},
			});
		}
		setOpen(false);
	};

	const onChangeValue = (value) => {
		setValue(value);

		if (!firstSetvalueFlag.current) {
			firstSetvalueFlag.current = true;
		}
	};

	useEffect(() => {
		if (!initialOpenedFlag.current) {
			initialOpenedFlag.current = true;
		}
	}, []);

	return (
		<>
			<Tooltip title="Time Frame Converter" arrow>
				<div
					className={classNames(
						styles.projectionDaysSVGWrapper,
						projectionDays !== null && styles.active,
						loading && "skeleton"
					)}
					onClick={() => setOpen(true)}
					ref={anchorRef}
					style={{ ...style, cursor: loading ? "default" : "pointer" }}
				>
					{!open && projectionDays !== null && (
						<div className={classNames(styles.clearWrapper, loading && styles.skeleton, loading && "skeleton")}>
							<ClearIcon
								onClick={(e) => {
									e.stopPropagation();
									dispatch(setProjectionDays(null));
									setValue(null);
								}}
								sx={{
									fontSize: 12,
									color: "white",
								}}
							/>
						</div>
					)}
					{loading ? <div style={{ width: "35px", height: "36px" }} /> : <PickProjectionDaysSVG />}
				</div>
			</Tooltip>

			{/* {projectionDays !== null && (
				<div className={styles.projectionInfoFootnote}>
					proj. <span style={{ fontWeight: "bold" }}>{projectionDays}</span>
				</div>
			)} */}
			<Popover
				id="pick-projection-days-popup"
				open={open}
				sx={{ mt: "-2px", ml: "-20px" }}
				anchorEl={anchorRef.current}
				onClose={() => setOpen(false)}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<div
					style={{
						width: "360px",
						height: "214px",
						position: "relative",
						padding: "10px 13px",
						paddingBottom: "15px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
				>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<div>
							<Typography variant="subtitle1">Choose a period of data: </Typography>
						</div>
						<div className={styles.secondSVGWrapper}>
							<PickProjectionDaysSVG />
						</div>
					</div>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							<Button
								variant={
									value === null || (projectionDays === null && initialOpenedFlag.current === false)
										? "contained"
										: "outlined"
								}
								onClick={() => onChangeValue(null)}
							>
								All
							</Button>
						</div>
						<div>
							<Button
								variant={
									value === 30 || (projectionDays === 30 && initialOpenedFlag.current === false)
										? "contained"
										: "outlined"
								}
								onClick={() => onChangeValue(30)}
							>
								30 Days
							</Button>
						</div>
						<div>
							<Button
								variant={
									value === 60 || (projectionDays === 60 && initialOpenedFlag.current === false)
										? "contained"
										: "outlined"
								}
								onClick={() => onChangeValue(60)}
							>
								60 Days
							</Button>
						</div>
						<div>
							<Button
								variant={
									value === 90 || (projectionDays === 90 && initialOpenedFlag.current === false)
										? "contained"
										: "outlined"
								}
								onClick={() => onChangeValue(90)}
							>
								90 Days
							</Button>
						</div>
					</div>
					<Typography variant="subtitle1">or</Typography>
					<div style={{ display: "flex", height: "36px" }}>
						<Input
							sx={{ borderRadius: "5px", paddingLeft: "5px", minWidth: "60%", height: "100%" }}
							placeholder="Enter custom number"
							value={value || ""}
							onChange={(e) => setValue(e.target.value)}
						></Input>
						<Button
							variant={firstSetvalueFlag.current === false || value === projectionDays ? "outlined" : "contained"}
							fullWidth={true}
							sx={{ ml: "10px" }}
							onClick={onApplyValue}
						>
							Apply
						</Button>
					</div>
				</div>
			</Popover>
		</>
	);
};

export default PickProjectionDaysPopup;
