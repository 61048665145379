export const HOSPITAL_CARDS_TYPE = {
    DOCTOR_DATA: 'doctorData',
    DCER_DATA: 'DCERData',
    INSURANCE_DATA: 'insuranceData',
    RETURNS_DATA: 'returnsData',
    NINETY_DAYS_DATA: 'ninetyDaysData',
    NURSE_DATA: 'nurseData',
    FLOORS_DATA: 'floorsData',
    DAYS_DATA: 'daysData',
    DX_DATA: 'dxData',
    SHIFT_DATA: 'shiftData',
    PERMISSION: 'permission',
    HOSPITAL_DATA: 'hospitalData',
    HOSPITALIZATIONS: 'hospitalizations',
    UNPLANNED: "unplannedHospitalTransfer",
    PLANNED: "plannedHospitalTransfer",
    TOTAL: "total",
}


