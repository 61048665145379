import _ from "lodash";
import { CHART_TAB_BUTTON } from "../../types/chart-dialog.type";
import { Typography } from "@mui/material";
import { TitleHeader } from ".";
import { toDisplayTime } from "../../utilis/date-formats";

const GraphReport = (props) => {
	const { graphData, alreadyChecked } = props;
	const getFilterNames = () => {
		if (graphData && graphData.selectedItem.length > 0 && graphData.filterData.length > 0) {
			let filterOptions = _.filter(graphData.filterData, ({ _id }) => {
				return _.every([_.includes(graphData.selectedItem, _id)]);
			});
			return _.map(filterOptions, "label").join(" | ");
		} else {
			return null;
		}
	};

	return (
		<>
			{graphData && graphData.selectedTab === CHART_TAB_BUTTON.CHART && (
				<>
					<Typography>Graph report list:</Typography>
					<Typography>FilterBy : {_.capitalize(graphData?.selectedFilter)}</Typography>
					<br />
					<table style={{ width: "100%" }}>
						<tr>
							<TitleHeader colSpan={2} title={graphData?.title} />
						</tr>
						{!alreadyChecked && (
							<tr>
								<td colSpan={2}>Filters : {getFilterNames()}</td>
							</tr>
						)}
						<tr>
							<td>Date</td>
							<td>Value</td>
						</tr>
						{graphData?.chartData?.filterData.length > 0 &&
							graphData?.chartData?.filterData.map((ele) => (
								<>
									<tr>
										<TitleHeader title={ele?.x} />
										<td>{ele?.y}</td>
									</tr>
								</>
							))}
					</table>
				</>
			)}
			{graphData && graphData.selectedTab === CHART_TAB_BUTTON.TABLE && (
				<>
					<Typography>Patients list 1 :</Typography>
					<br />
					<table style={{ width: "100%" }}>
						<tr>
							<TitleHeader title={graphData?.title} />
						</tr>
						{!alreadyChecked && (
							<tr>
								<td colSpan={2}>Filters : {getFilterNames()}</td>
							</tr>
						)}
					</table>
					{graphData.chartData &&
						graphData.chartData.filterPatients &&
						graphData.chartData.filterPatients.length > 0 && (
							<table style={{ width: "100%" }}>
								<tr>
									<th>Last name</th>
									<th>Fist name</th>
									<th>DOB</th>
									<th>Date of ADT</th>
									<th>Type</th>
								</tr>
								{graphData.chartData.filterPatients.map((ele) => (
									<tr>
										<td>{ele?.lastName}</td>
										<td>{ele?.firstName}</td>
										<td>{toDisplayTime(ele.DOB, "MM/DD/YYYY")}</td>
										<td>{toDisplayTime(ele.dateOfADT, "MM/DD/YYYY")}</td>
										<td>
											{ele.type === "transfer"
												? ele.transferType
														.replace(/([A-Z])/g, " $1")
														// uppercase the first character
														.replace(/^./, function (str) {
															return str.toUpperCase();
														})
												: ele.type.replace(/^./, function (str) {
														return str.toUpperCase();
												  })}
										</td>
									</tr>
								))}
							</table>
						)}
					{/* <span
						className="print-table-ref"
						dangerouslySetInnerHTML={{
							__html: graphData?.tableElementRef?.current?.innerHTML,
						}}
					/> */}
				</>
			)}
			{graphData && graphData.selectedTab === CHART_TAB_BUTTON.BUILDING && (
				<>
					<Typography>Buildings list:</Typography>
					<br />
					<table style={{ width: "100%" }}>
						<tr style={{ borderBottom: "none !important" }}>
							<td style={{ border: "0.00001em solid #bfb8b8 !important" }}>{graphData?.title}</td>
						</tr>
						{!alreadyChecked && (
							<tr style={{ borderBottom: "none !important" }}>
								<td style={{ border: "0.00001em solid #bfb8b8 !important" }}>Filters : {getFilterNames()}</td>
							</tr>
						)}
					</table>
					<span
						className="print-table-ref"
						dangerouslySetInnerHTML={{
							__html: graphData?.buildingElementRef?.current?.innerHTML,
						}}
					/>
				</>
			)}
		</>
	);
};

export default GraphReport;
